import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Title, config } from '@awdis/components'

import Layout, { LayoutData } from '../../components/Layout'
import Seo from '../../components/Seo'
import HubspotContactForm from '../../components/HubspotContactForm'

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
`

const PageDescription = styled.p`
  margin-top: 32px;
  margin-bottom: 32px;
`

const PageFooter = styled.p`
  margin-top: 64px;
  margin-bottom: 48px;
`

type ContactBusinessProps = {
  pageContext: {
    layoutData: LayoutData
    page: {
      title: string
      url: string
    }
  }
}

const defaultProps = {}

const ContactBusiness = function ContactBusiness({
  pageContext: {
    layoutData,
    layoutData: { brandDescription },
    page,
  },
}: ContactBusinessProps) {
  return (
    <Layout data={layoutData}>
      <Seo
        title={page.title}
        description={brandDescription}
        type="website"
        pathname={page.url}
      />
      <Title as="h2" size="small" marginBottom="32px">
        {config.brand.brandDialect === 'US'
          ? 'I am a business that customizes garments looking for an approved distributor that sells your products'
          : 'I am a business that customises garments looking for an approved distributor that sells your products'}
      </Title>

      <PageDescription>
        <p>
          Our products are available from the best distributors in the
          promotional, workwear and corporate wear industry.
        </p>

        <p>
          To help us put you in touch with your nearest distributor, please
          complete the form below.
        </p>

        {/* <p>
          You can also contact our Business Development Team on{' '}
          <BasicLink href={`tel:${contactNumber.tel}`}>{contactNumber.readable}</BasicLink>.
        </p> */}
      </PageDescription>

      <HubspotContactForm contactFormType="business_user" />

      <PageFooter>
        <p>
          The answers to our most common questions such as, "Where can I buy
          your products from?" & "How can I get another garment with a specific
          print?" Can be found here on our{' '}
          <StyledLink to="/faqs">FAQs page</StyledLink>
        </p>
      </PageFooter>
    </Layout>
  )
}

ContactBusiness.defaultProps = defaultProps

export default ContactBusiness
