import * as React from 'react'
import styled from 'styled-components'
import { properties, buttonStyle, config } from '@awdis/components'

import BasicLink from '../BasicLink'

const { ResponsiveBreakpoints } = properties

const Container = styled.div`
  #hubspot-contact-form {
    > form {
      font-size: 1rem;

      display: grid;
      grid-template-columns: 1fr;
      gap: 18px;

      .hs-richtext {
        font-size: 1rem;
        margin-bottom: 18px;

        h1 {
          font-size: 1.2rem;
          text-transform: uppercase;
          font-weight: 500;
          margin-top: 2rem;
        }

        p {
          font-size: 1rem;
          margin-bottom: 0;
          color: #292929 !important;

          span {
            font-size: 1rem !important;
            color: #292929 !important;
          }
        }
      }

      ul {
        margin: 0;

        li {
          list-style: none;
        }
      }

      .hs-error-msgs {
        color: red;
        margin-top: 2px;
        font-size: 12px;
      }

      .field {
        width: auto;

        > label {
          color: #292929;
        }

        > .input {
          margin-top: 0.6rem;
          margin-right: 0;

          > input {
            width: 100%;
            background-color: #fff;
            border: 1px solid #5d5d5d;
            border-radius: 1px;
            font-size: 1rem;
            color: #292929;
            height: 40px;
            padding: 0.5rem;
            transition: 0.25s;
            box-shadow: inset 0 1px 3px 0 rgb(86 85 85 / 50%);

            &:focus {
              background-color: #f9f9f9;
              border-color: #4a90e2;
              outline: none;
            }
          }

          > ul.inputs-list {
            > li {
              > label {
                > input[type='checkbox'],
                input[type='radio'] {
                  margin-right: 6px;
                }
              }
            }
          }

          > select {
            background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
              no-repeat 100% 50%;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;

            width: 100%;
            background-color: #fff;
            border: 1px solid #5d5d5d;
            border-radius: 1px;
            font-size: 1rem;
            color: #292929;
            height: 40px;
            padding: 0.5rem;
            transition: 0.25s;
            box-shadow: inset 0 1px 3px 0 rgb(86 85 85 / 50%);

            &:focus {
              background-color: #f9f9f9;
              border-color: #4a90e2;
              outline: none;
            }
          }

          > textarea {
            width: 100%;
            background-color: #fff;
            border: 1px solid #5d5d5d;
            border-radius: 1px;
            font-size: 1rem;
            color: #292929;
            height: 100px;
            padding: 0.5rem;
            transition: 0.25s;
            box-shadow: inset 0 1px 3px 0 rgb(86 85 85 / 50%);

            &:focus {
              background-color: #f9f9f9;
              border-color: #4a90e2;
              outline: none;
            }
          }
        }
      }

      .legal-consent-container {
        margin-top: 32px;

        .hs-dependent-field {
          .input {
            ul {
              li {
                label {
                  display: flex;
                  align-items: center;

                  input {
                    margin-right: 6px;
                  }
                  span {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }

      .hs_recaptcha {
        .grecaptcha-badge {
          visibility: visible;
        }
      }

      > fieldset {
        max-width: 100%;

        &.form-columns-2 {
          display: grid;
          grid-template-columns: 1fr;
          gap: 32px;

          @media (min-width: ${ResponsiveBreakpoints.Small}) {
            grid-template-columns: 1fr 1fr;
          }
        }
      }

      > .hs_error_rollup {
        > .hs-error-msgs {
          font-size: 1rem;
        }
      }

      > .hs-submit {
        margin-top: 24px;

        > .actions {
          @media (min-width: ${ResponsiveBreakpoints.Small}) {
            display: flex;
            justify-content: center;
          }

          > input[type='submit'] {
            ${buttonStyle};
            height: 40px;
            width: 100%;

            @media (min-width: ${ResponsiveBreakpoints.Small}) {
              width: 150px;
            }
          }
        }
      }
    }
  }

  .bdt-contact-info {
    margin-top: 32px;
    font-size: 12px;
    text-align: center;
  }

  .submitted-message {
    padding-top: 5rem;
    padding-bottom: 5rem;
    color: black;
    text-align: center;
  }
`

const region = process.env.GATSBY_HUBSPOT_REGION
const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID

type ContactFormType = 'individual_user' | 'embroiderer_user' | 'business_user'

const getHubspotFormId = (contactFormType: ContactFormType) => {
  switch (contactFormType) {
    case 'individual_user':
      return process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID
    case 'embroiderer_user':
      return process.env.GATSBY_HUBSPOT_EMBROIDERER_CONTACT_FORM_ID
    case 'business_user':
      return process.env.GATSBY_HUBSPOT_BUSINESS_CONTACT_FORM_ID
    default:
      return undefined
  }
}

export type HubspotContactFormProps = {
  contactFormType: ContactFormType
  onSubmit?: () => void
}

const HubspotContactForm: React.FC<HubspotContactFormProps> = props => {
  const hubspotFormId = getHubspotFormId(props.contactFormType)

  const [showAmazonMessage, setShowAmazonMessage] = React.useState(false)

  const createHubspotForm = () => {
    // @ts-ignore
    if (window.hbspt && region && portalId && hubspotFormId) {
      // @ts-ignore
      window.hbspt.forms.create({
        region,
        portalId,
        formId: hubspotFormId,
        target: '#hubspot-contact-form',
      })
    }
  }

  const handleHubspotFormEvent = (event: MessageEvent) => {
    if (
      props.contactFormType === 'individual_user' &&
      event.data.id === process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID &&
      event.data.type === 'hsFormCallback' &&
      (event.data.eventName === 'onFormFailedValidation' ||
        event.data.eventName === 'onFormSubmit')
    ) {
      // check if extra tickbox is ticked
      //  - if yes: show amazon success message
      //  - if no: do nothing

      const { data: formValues } = event.data

      const userOptOutField = Array.isArray(formValues)
        ? formValues.find(
            formValue => formValue.name === 'i_dont_want_to_provide_my_details'
          )
        : false

      const isBoxTicked = !Array.isArray(userOptOutField.errorTypes)

      if (isBoxTicked) {
        setShowAmazonMessage(true)

        if (props.onSubmit) {
          props.onSubmit()
        }
      }
    }

    if (
      props.contactFormType === 'individual_user' &&
      event.data.id === process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID &&
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      setShowAmazonMessage(true)

      if (props.onSubmit) {
        props.onSubmit()
      }

      // const { data: formValues } = event.data

      // const numberOfItemsToOrder = Array.isArray(formValues)
      //   ? formValues.find(formValue => formValue.name === 'nbitems')
      //   : undefined

      // if (!numberOfItemsToOrder) {
      //   return
      // }

      // if (numberOfItemsToOrder.value === '< 20') {
      //   // show amazon message
      //   setShowAmazonMessage(true)
      // } else {
      //   // redirect to embroiderer contact form
      //   window.location.href = '/contact/embroiderer'
      // }
    }
  }

  const handleHubspotFormChange = (event: Event) => {
    const target = event.target as any

    if (
      props.contactFormType === 'individual_user' &&
      target.id ===
        `i_dont_want_to_provide_my_details-${
          process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID
        }`
    ) {
      const firstNameField = document.querySelector(
        `#firstname-${process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID}`
      ) as any
      const lastNameField = document.querySelector(
        `#lastname-${process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID}`
      ) as any
      const emailField = document.querySelector(
        `#email-${process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID}`
      ) as any

      const legalConsentField = document.querySelector(
        `#LEGAL_CONSENT\\.subscription_type_9959308-${
          process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID
        }`
      ) as any

      const legalConsentField2 = document.querySelector(
        `#LEGAL_CONSENT\\.processing-${
          process.env.GATSBY_HUBSPOT_INDIVIDUAL_CONTACT_FORM_ID
        }`
      ) as any

      if (target.checked) {
        emailField.disabled = true
        emailField.style.opacity = 0.25

        firstNameField.disabled = true
        firstNameField.style.opacity = 0.25

        lastNameField.disabled = true
        lastNameField.style.opacity = 0.25

        legalConsentField.disabled = true
        legalConsentField.style.opacity = 0.6

        legalConsentField2.disabled = true
        legalConsentField2.style.opacity = 0.6
      } else {
        emailField.disabled = false
        emailField.style.opacity = 1.0

        firstNameField.disabled = false
        firstNameField.style.opacity = 1.0

        lastNameField.disabled = false
        lastNameField.style.opacity = 1.0

        legalConsentField.disabled = false
        legalConsentField.style.opacity = 1.0

        legalConsentField2.disabled = false
        legalConsentField2.style.opacity = 1.0
      }
    }
  }

  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', createHubspotForm)
    window.addEventListener('message', handleHubspotFormEvent)
    window.addEventListener('change', handleHubspotFormChange)

    return () => {
      script.removeEventListener('load', createHubspotForm)
      window.removeEventListener('message', handleHubspotFormEvent)
      window.removeEventListener('change', handleHubspotFormChange)
    }
  }, [])

  if (!region || !portalId || !hubspotFormId) {
    return null
  }

  return (
    <Container>
      {!showAmazonMessage ? (
        <div>
          <div id="hubspot-contact-form" />

          {(props.contactFormType === 'embroiderer_user' ||
            props.contactFormType === 'business_user') && (
            <p className="bdt-contact-info">
              Alternatively, you can also contact our Business Development Team
              on{' '}
              <BasicLink href={`tel:${config.contact.contactNumber.tel}`}>
                {config.contact.contactNumber.readable}
              </BasicLink>
              .
            </p>
          )}
        </div>
      ) : null}

      {showAmazonMessage ? (
        <p className="submitted-message">
          As we don’t sell our products directly, the best way to get your hands
          on our products is to go to Amazon or eBay and simply type the product
          that caught your attention!
        </p>
      ) : null}
    </Container>
  )
}

export default HubspotContactForm
